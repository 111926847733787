
.form-parts {
  background: -webkit-linear-gradient(#121d3d, #0d181b);;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
.form-parts-first {
  line-height: 3rem;
  font-size: 16px;
}
.form-parts-first h1 {
  color: hsla(240, 100%, 50%, 1);
}
.form-parts-first span {
  background: -webkit-linear-gradient(#144ae8, #0fd0f9);
  -webkit-background-clip: text;
  backdrop-filter: blur(5px);
  -webkit-text-fill-color: transparent;
}
.form-parts-first p {
  font-size: 25px;
  padding-top: 7px;
  line-height: 2rem;
}

form div {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

form label {
  padding-block: 5px;
}
form input,
textarea {
  
  border: transparent;
  border-bottom: 1px solid #fff!important ;
  background-color: transparent;
  outline: 0;
  padding-top: 4px;
  padding-bottom: 12px;
  font-size: 16px;
  color: #fff;
  line-height: 1.3rem;
}
textarea {
  resize: none;
}
form input:focus,
form input:active {
  border-bottom: 2px solid hsla(240, 100%, 50%, 1);
  transition: all 0.2s;
  background-color: transparent;
}
form input::placeholder {
  line-height: 20px;
  font-size: 18px;
  color: hsla(0, 0%, 0%, 0.3);
}
.text-area input {
  text-wrap: wrap;
  word-break: break-all;
  word-wrap: break-word;
  height: 50px;
}
