@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* App.css */
*{
  margin: 0;
  padding:0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif !important;
  background-color: black;
  overflow-x: hidden !important;
}

.get__in_touch {
  @apply flex gap-5 justify-start items-center rounded-lg bg-transparent border outline-none  hover:transition-all hover:duration-700 border-white/20 placeholder:text-white placeholder:font-light py-3 px-3 text-lg w-full;
}

.about__get__in_touch {
  @apply flex gap-2 justify-start items-center text-white/60 text-lg;
}
.modal {
  @apply flex flex-col mb-10 bg-[rgba(65,47,123,0.15)] rounded-lg w-[90%] md:w-[80%] lg:w-[70%] xl:w-[60%] overflow-y-auto lg:h-[90%];
}
.about__modal-container2 {
  @apply py-4 lg:border-l border-dashed flex flex-col gap-6 px-2 md:px-10 flex-1 lg:overflow-y-auto 2xl:text-xl;
}
.about__modal_base-container {
  @apply flex flex-col lg:flex-row overflow-y-auto overflow-x-hidden;
}
.work__modal-container {
  @apply flex flex-col px-6 pt-10 pb-6 md:px-12 overflow-y-auto 2xl:gap-10;
}
